<script>
import log from '@/lib/utils/log'

export default {
	name: 'ErrorBoundary',
	data: () => ({
		hasError: false
	}),
	errorCaptured(err, _, info) {
		log.error(`Vue error: ${info}`, null, err.stack)
		this.hasError = true
	},
	render() {
		// need to check because first load doesn't have slots for backoffice
		if (!this.$slots.default) {
			return
		}
		return this.$slots.default[0]
	}
}
</script>
